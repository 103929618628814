import { createAction, createSlice } from "@reduxjs/toolkit";
import { REDUX_ACTIONS } from "../consts";

// This is a dummy action that only logs user navigation actions
export const userNavigated = createAction(REDUX_ACTIONS.USER_NAVIGATED);

// Helper function to parse URL search params into an object
function parseQueryString(search) {
  const params = new URLSearchParams(search);
  const queryObj = {};

  for (const [key, value] of params.entries()) {
    // Handle arrays in query params
    if (key.endsWith("[]")) {
      const cleanKey = key.slice(0, -2);
      if (!queryObj[cleanKey]) {
        queryObj[cleanKey] = [];
      }
      queryObj[cleanKey].push(value);
    } else {
      queryObj[key] = value;
    }
  }

  return queryObj;
}

const initialState = {
  location: {
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    state: undefined,
    query: parseQueryString(window.location.search)
  },
  action: "POP" // 'PUSH' | 'POP' | 'REPLACE'
};

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    updateLocation: (state, action) => {
      state.location = {
        ...action.payload.location,
        query: parseQueryString(action.payload.location.search)
      };
      state.action = action.payload.action;
    }
  }
});

export const { updateLocation } = routerSlice.actions;

// Selectors
export const selectLocation = (state) => state.router.location;
export const selectPathname = (state) => state.router.location.pathname;
export const selectSearch = (state) => state.router.location.search;
export const selectHash = (state) => state.router.location.hash;
export const selectQuery = (state) => state.router.location.query;
export const selectAction = (state) => state.router.action;

// Selector to get specific query parameter
export const selectQueryParam = (param) => (state) =>
  state.router.location.query[param];

export default routerSlice.reducer;
