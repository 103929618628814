import { createListenerMiddleware, createAction } from "@reduxjs/toolkit";
import { firestore, auth } from "../firebase";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { captureException } from "../utils/errorHandlers";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  type: "user/setAuth",
  effect: async (action, listenerApi) => {
    handleSq3r(listenerApi, 0);
  }
});

listenerMiddleware.startListening({
  type: "texts/setSelectedTextId",
  effect: async (action, listenerApi) => {
    handleSq3r(listenerApi, action.payload.text_id);
  }
});

const gotSq3r = createAction("gotSq3r");
const listenerUnsubscribeList = [];

function handleSq3r(storeAPI, text_id) {
  if (auth.currentUser) {
    listenerUnsubscribeList.forEach((a) => a && a());
    listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);

    if (text_id > 0) {
      // SQ3R handling
      const sq3rRef = doc(
        firestore,
        `sq3r/${auth.currentUser.uid}/texts/${text_id}`
      );
      const unsubscribeSq3r = onSnapshot(
        sq3rRef,
        (snapshot) => {
          if (snapshot.exists()) {
            storeAPI.dispatch(
              gotSq3r({ ...snapshot.data(), text_id: text_id })
            );
          } else {
            const emptyGEdoc = {
              updatedAt: new Date().toISOString(),
              highlights: [],
              questions: [],
              status: "Active",
              grMode: "light",
              grStage: 0,
              grQuestionId: false,
              grShowAnswers: false,
              grShowHighlights: false
            };
            setDoc(sq3rRef, emptyGEdoc);
          }
        },
        (error) => {
          captureException(error, `Failed to fetch SQ3R from firebase`);
        }
      );
      listenerUnsubscribeList.push(unsubscribeSq3r);
    }
  }
  auth.onAuthStateChanged(function (user) {
    if (!user) {
      listenerUnsubscribeList.forEach((a) => a && a());
      listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);
    }
  });
}

export default listenerMiddleware.middleware;
