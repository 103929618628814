import { v4 as uuid } from "uuid";

export const HIGHLIGHTS = {
  CONTEXT: {
    ANSWERS: "Answers",
    HIGHLIGHTS: "Highlights"
  },
  COLOR: {
    STANDARD_TASK: "#fccbfc",
    GREEN: "#BEF3BF",
    CYAN: "#B9E7FF",
    ORANGE: "#ffa284",
    RED: "#FF7F74",
    YELLOW: "#FFE690",
    PINK: "#ECC5FF"
  }
};

export const CHAT = {
  TYPE: {
    REVIEW: "REVIEW",
    READER: "READER"
  }
};

export const FAB_STYLE_VIEW = {
  EXPANDED: "expanded",
  MINIMIZED: "minimized"
};

export const USER_ACTIONS = {
  TASK_DELETE_AI_QUESTION: "TASK_DELETE_AI_QUESTION",
  TASK_ADD_MANUAL_QUESTION: "TASK_ADD_MANUAL_QUESTION",
  TASK_EDIT_AI_QUESTION: "TASK_EDIT_AI_QUESTION",
  TASK_GENERATE_AI_QUESTION: "TASK_GENERATE_AI_QUESTION",
  TASK_REGENERATE_AI_QUESTION: "TASK_REGENERATE_AI_QUESTION",
  TASK_GENERATE_AI_ASSIGNMENT: "TASK_GENERATE_AI_ASSIGNMENT",
  TASK_PUBLISH: "TASK_PUBLISH",
  TASK_CREATION_SESSION: "TASK_CREATION_SESSION"
};

export const TASK = {
  TYPE: {
    STANDARD: "standard",
    GUIDED_READING: "guidedReading",
    PEER_REVIEW: "peerReview",
    JOURNEY: "journey"
  },
  SUBMISSION_STATUS: {
    SUBMITTED: "Submitted",
    PENDING: "Pending",
    PENDING_REVIEW: "pendingReview",
    ACTIVE: "Active",
    GRADED: "Graded",
    LATE: "late",
    MISSED: "missed"
  },
  STEPS: {
    REVIEW: "review"
  },
  QUESTION_TYPES: {
    FIND_IN_TEXT: "FIND_IN_TEXT",
    OPEN_ENDED: "OPEN_ENDED",
    MULTI_CHOICE: "MULTI_CHOICE",
    PEER_REVIEW: "PEER_REVIEW",
    GUIDED_READING: "GUIDED_READING"
  }
};

export const QUESTION_INTL = {
  FIND_IN_TEXT: {
    translationId: "task.type.find",
    defaultMessage: "Find in Text"
  },
  OPEN_ENDED: {
    translationId: "task.type.open",
    defaultMessage: "Open Ended"
  },
  MULTI_CHOICE: {
    translationId: "task.type.multiple",
    defaultMessage: "Multiple Choice"
  },
  GUIDED_READING: {
    translationId: "task.type.guidedReading",
    defaultMessage: "Guided Reading"
  },
  PEER_REVIEW: {
    translationId: "task.type.peerReview",
    defaultMessage: "Peer Review"
  }
};

export const QUESTION = {
  FIND_IN_TEXT: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "FIND_IN_TEXT",
    content: "",
    includeCitation: true,
    points: 0,
    quotes: [],
    options: [],
    aiQuestion: false
  },
  OPEN_ENDED: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "OPEN_ENDED",
    content: "",
    includeCitation: true,
    points: 0,
    concepts: [],
    quotes: [],
    options: [],
    question_context: TASK.TYPE.STANDARD,
    aiQuestion: false
  },
  MULTI_CHOICE: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "MULTI_CHOICE",
    content: "",
    includeCitation: true,
    points: 0,
    shouldSelect: -1,
    quotes: [],
    options: [],
    aiQuestion: false
  },
  PEER_REVIEW: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "PEER_REVIEW",
    content: "",
    includeCitation: false,
    points: 0,
    options: [],
    aiQuestion: false
  }
};

export function Question(QUESTION, order, context = TASK.TYPE.STANDARD) {
  return {
    ...QUESTION,
    ...{ tempId: uuid(), order: order, question_context: context }
  };
}

export const USER_TYPE = {
  TEACHER: "TEACHER",
  STUDENT: "STUDENT",
  MENTOR: "MENTOR",
  ADMIN: "ADMIN"
};

export const appActions = {
  SHOULD_LOG: "SHOULD_LOG",
  LOGIN: "LOGIN",
  READ_FEEDBACK: "READ_FEEDBACK",
  READ_TASK: "READ_TASK",
  CREATE_TASK: "CREATE_TASK",
  READ_TEXT: "READ_TEXT",
  UPDATE_INTERACTION: "UPDATE_INTERACTION",
  CREATE_INTERACTION: "CREATE_INTERACTION",
  DELETE_INTERACTION: "DELETE_INTERACTION",
  SHOW_IN_TEXT: "SHOW_IN_TEXT",
  ARTICLE_READING_EVENT: "ARTICLE_READING_EVENT"
};

export const INTERACTION_TYPES = {
  COMMENT: "COMMENT",
  QUOTE: "QUOTE",
  QUESTION: "QUESTION",
  ANSWER: "ANSWER",
  FEEDBACK: "FEEDBACK",
  REVIEW: "REVIEW",
  READER: "READER",
  CONTAINER: "CONTAINER",
  SUGGESTION: "SUGGESTION",
  REASON: "REASON"
};

export const INTERACTION_SUBTYPES = {
  MULTI_CHOICE: "MULTI_CHOICE",
  FIND_IN_TEXT: "FIND_IN_TEXT",
  PEER_REVIEW: "PEER_REVIEW",
  GUIDED_READING: "GUIDED_READING",
  OPEN_ENDED: "OPEN_ENDED",
  COMMENT: "COMMENT",
  QUOTE: "QUOTE",
  DEFAULT: "DEFAULT",
  TASK: "TASK",
  REPLY: "REPLY",
  SELECTION: "SELECTION"
};

export const PRIVACY = {
  ME_ONLY: "ME_ONLY",
  PUBLIC: "PUBLIC",
  ME_AND_TEACHER: "ME_AND_TEACHER",
  PEERS_IN_COURSE: "PEERS_IN_COURSE",
  SELECTED_FEW: "SELECTED_FEW",
  FEEDBACK: "FEEDBACK"
};

export const ANNOTATION_TYPES = {
  HIGHLIGHT: "HIGHLIGHT",
  UNDERLINE: "UNDERLINE",
  THREAD: "THREAD"
};

export const BREAKPOINTS = {
  DESKTOP: 1440
};

export const PRIMARY_SIDEBAR_STATE = {
  EXPANDED: "EXPANDED",
  COLLAPSED: "COLLAPSED"
};

export const SECONDARY_SIDEBAR_STATE = {
  COLLAPSED: "COLLAPSED",
  THUMBNAILS: "THUMBNAILS",
  SUGGESTIONS: "SUGGESTIONS",
  COMMENTS: "COMMENTS"
};

export const COMMENT_PANEL_VIEW = {
  SINGLE_THREAD: "SINGLE_THREAD",
  NEW_COMMENT: "NEW_COMMENT",
  SELECTED_THREADS: "SELECTED_THREADS",
  ALL_THREADS: "ALL_THREADS"
};

export const FEATURES = {
  READER: "READER",
  TASK: "TASK",
  SUGGESTIONS: "SUGGESTIONS",
  DEFAULT: "DEFAULT",
  CROSS_FEATURE: "CROSS_FEATURE",
  COMMENTS: "COMMENTS",
  ANALYTICS: "ANALYTICS",
  INTERACTIONS: "INTERACTIONS"
};

export const READER_MODES = {
  GUIDED_READING: "GUIDED_READING",
  FREE_ANNOTATIONS: "FREE_ANNOTATIONS"
};

export const USER_PROFILE = {
  CUSTOM_CONFIG_PATH: "userProfiles/customConfig",
  CROSS_FEATURE_CONFIG_PATH: "userProfiles/crossFeatureConfig"
};

export const LOCALES = {
  en: { tag: "en", language: "English", textDirection: "ltr", rtl: false },
  he: { tag: "he", language: "Hebrew", textDirection: "rtl", rtl: true }
};

export const NOTIFICATION_INTL = {
  STUDENT: {
    notifyStudentsOnNewTask: `A new Assignment, $DATA, is waiting for you`,
    notifyStudentOnFeedback: "A new feedback is waiting for you",
    notifyStudentOnUpdatedFeedback:
      "The feedback for assignment $DATA has been updated",
    notifyOnPeerReviewReflection: `$DATA is waiting for your review`,
    notifyOnPeerReviewResponse: `$DATA reviewed your work`,
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyOnPeerReviewReply: `$DATA replied to your review`,
    notifyStudentOnNewCommentReply: "$DATA replied to your comment on $DATA2",
    notifyStudentOnResubmittingTask: "Assignment $DATA is pending resubmission",
    notifyStudentOnDeletedAssignment: "Assignment $DATA has been deleted"
  },
  TEACHER: {
    notifyTeachersOnSubmission: `$DATA submitted an Assignment`,
    notifyOnPeerReviewReflection: `$DATA submitted a reflection`,
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyOnPeerReviewResponse: `$DATA submitted a review`,
    notifyOnPeerReviewReply: "$DATA replied to your review"
  },
  COURSE: {
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyCourseOnNewCommentReply: "$DATA replied to a comment on $DATA2"
  }
};

export const IDLE_PERIOD = 1000 * 60 * 10; // 10 min
export const ACTIVITY_EVENT = { START: "START", END: "END" };
export const TEXT_TYPE = {
  PDF: "PDF",
  EPUB: "EPUB"
};

export const ENGAGEMENT_SCORE_COEFFICIENT = {
  LOW_SCORE: 100,
  MEDIUM_SCORE: 50,
  HIGH_SCORE: 0,
  MISSED: 100,
  LATE: 50,
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

export const ACTION_OBJ = {
  READING_SESSIONS: "ReadingTime",
  WRITING_SESSIONS: "writingTime",
  GUIDED_READING_INTERACTIONS: "questionsCreated",
  REAL_TIME_INTERACTIONS: "comments",
  SESSIONS: "sessions"
};

export const CHART_ACTIVITY_BUCKET = {
  PER_CLASS: "daily_activity_per_class",
  PER_USER: "daily_activity_per_active_users"
};

export const PEER_REVIEW_TASK = {
  REFLECTION: {
    MY_REFLECTION: "My reflection"
  },
  REVIEW: {
    MY_REVIEW: "My review",
    REVIEW: "review",
    USER_REVIEW: `$DATA's review`
  },
  FEEDBACK: {
    MY_FEEDBACK: "My feedback",
    TEACHER_FEEDBACK: "Teacher feedback"
  },
  SIZE: {
    LARGE: "large",
    SMALL: "small"
  },
  ZEN_MODE: {
    FULL_SCREEN: "Full screen",
    EXIT_FULL_SCREEN: "Exit full screen",
    ICON_FULL: "icon.EnterFullScreen",
    ICON_EXIT: "icon.exitFullScreen"
  }
};

export const COURSE_ACTIONS_FOR_ANALYSES = {
  SESSIONS: "General engagement",
  READING_SESSIONS: "Reading time",
  WRITING_SESSIONS: "Writing time",
  REAL_TIME_INTERACTIONS: "Comments",
  QUOTE_INTERACTIONS: "Citations",
  GUIDED_READING_INTERACTIONS: "Questions"
};

export const SAVED_THUMBNAILS_CONFIG = {
  FORMAT: "webp",
  QUALITY: 0.6
};

export const REPORTS_VERSION = {
  NEW: "NEW",
  OLD: "OLD"
};

export const SUBMISSION_STATUS = {
  MISSED: "missed",
  LATE_PENDING: "latePending",
  LATE: "late",
  ON_TIME: "onTime",
  PENDING: "pending"
};

export const ENGAGEMENT_CATEGORIES = {
  MODERATE: "Moderate",
  HIGH: "High",
  VERYHIGH: "Very High"
};

export const FEATURE_FLAGS = {
  TEACHER_ANALYTICS: "TEACHER_ANALYTICS",
  ADJUSTABLE_SIDEBAR: "ADJUSTABLE_SIDEBAR",
  INTRODUCTION_EMAIL: "INTRODUCTION_EMAIL",
  SPLIT_BOT: "SPLIT_BOT",
  FIRST_HIGHLIGHT_EFFECT: "FIRST_HIGHLIGHT_EFFECT",
  NEW_ASSIGNMENT_LAYOUT: "NEW_ASSIGNMENT_LAYOUT"
};

export const SECONDARY_SIDEBAR_ACTION_BUTTONS = {
  COMMENTS: "Comments",
  THUBMNAILS: "Thumbnails",
  SUGGESTIONS: "Suggestions",
  COLLAPSED: "Collapsed"
};

export const REDUX_ACTIONS = {
  USER_NAVIGATED: "userNavigated",
  LOCATION_CHANGED: "userNavigated"
};

export const PRODUCTION_PROJECT_IDS = ["pangea-291110", "alethea-dda54"];

export const HEATMAPS_COLORS = [
  "#d7f7e6",
  "#aeefce",
  "#8ae1bb",
  "#5dd6a3",
  "#13cc8c",
  "#ffd863",
  "#ffaf2c",
  "#ff8719",
  "#ff3906",
  "#f80000"
];

export const TASK_CREATION_BOT_QUESTION_KIND = [
  {
    key: "Comprehension",
    label: "task.create.assignment.bot.questionKinds.comprehension",
    taskType: [TASK.TYPE.STANDARD]
  },
  {
    key: "Analysis",
    label: "task.create.assignment.bot.questionKinds.analysis",
    taskType: [TASK.TYPE.STANDARD]
  },
  {
    key: "Interpretation",
    label: "task.create.assignment.bot.questionKinds.interpretation",
    taskType: [TASK.TYPE.STANDARD]
  },
  {
    key: "Comparative",
    label: "task.create.assignment.bot.questionKinds.comparative",
    taskType: [TASK.TYPE.STANDARD]
  },
  {
    key: "Critique",
    label: "task.create.assignment.bot.questionKinds.critique",
    taskType: [TASK.TYPE.STANDARD]
  },
  {
    key: "BigPicture",
    label: "task.create.assignment.bot.questionKinds.bigPicture",
    taskType: [TASK.TYPE.JOURNEY]
  }
];

export const TEMP_MILESTONE_HIGHLIGHTS = [
  {
    user_type: "TEACHER",
    content: "There were no portents",
    task_id: null,
    user_uid: "vrkgZNsa1nOAbhZ3aQc1nZiIV1t1",
    interaction_id: "0e585dd3-a9dd-49aa-aa01-179bfb07004b",
    interaction_type: "ANSWER",
    interaction_subtype: "QUOTE",
    text_id: 4,
    course_id: 6,
    submission_id: null,
    privacy: "ME_ONLY",
    id: "d6cfe3da-773f-4654-b1a3-dafeb4ae612e",
    cfi: "epubcfi(/4/4!/38,/1:0,/1:22)",
    isPdf: true,
    pdfPosition: [
      {
        pageNumber: 1,
        pageRects: [
          {
            x1: 200.796875,
            y1: 161.671875,
            x2: 493.6913452148438,
            y2: 192.671875,
            width: 1580,
            height: 2044,
            pageNumber: 1
          }
        ],
        cfi: "epubcfi(/4/4!/38,/1:0,/1:22)"
      }
    ],
    color: "#fccbfc",
    question_context: "standard"
  },
  {
    user_type: "TEACHER",
    content:
      "he newscasters spoke of it in a hush, a wobble to their lips. Nobody understood what was happening",
    task_id: null,
    user_uid: "vrkgZNsa1nOAbhZ3aQc1nZiIV1t1",
    interaction_id: "0e585dd3-a9dd-49aa-aa01-179bfb07004b",
    interaction_type: "ANSWER",
    interaction_subtype: "QUOTE",
    text_id: 4,
    course_id: 6,
    submission_id: null,
    privacy: "ME_ONLY",
    id: "2dc21fef-fb01-49bd-9dfe-3068142ff5eb",
    cfi: "epubcfi(/4/4!,/56/1:30,/68/1:9)",
    isPdf: true,
    pdfPosition: [
      {
        pageNumber: 1,
        pageRects: [
          {
            x1: 591.67431640625,
            y1: 275.515625,
            x2: 622.766662597656,
            y2: 306.515625,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 200.796875,
            y1: 313.53125,
            x2: 771.643798828125,
            y2: 344.53125,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 200.796875,
            y1: 351.34375,
            x2: 698.482177734375,
            y2: 382.34375,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 200.796875,
            y1: 389.359375,
            x2: 333.0145263671875,
            y2: 420.359375,
            width: 1580,
            height: 2044,
            pageNumber: 1
          }
        ],
        cfi: "epubcfi(/4/4!,/56/1:30,/68/1:9)"
      }
    ],
    color: "#fccbfc",
    question_context: "standard"
  },
  {
    user_type: "TEACHER",
    content: "We had no choice, we were happy he was hale2 and well",
    task_id: null,
    user_uid: "vrkgZNsa1nOAbhZ3aQc1nZiIV1t1",
    interaction_id: "0e585dd3-a9dd-49aa-aa01-179bfb07004b",
    interaction_type: "ANSWER",
    interaction_subtype: "QUOTE",
    text_id: 4,
    course_id: 6,
    submission_id: null,
    privacy: "ME_ONLY",
    id: "076da4fa-3d7f-49da-8185-0ab39ad34847",
    cfi: "epubcfi(/4/4!,/96/1:10,/106/1:8)",
    isPdf: true,
    pdfPosition: [
      {
        pageNumber: 1,
        pageRects: [
          {
            x1: 301.5726928710938,
            y1: 692.90625,
            x2: 766.331787109375,
            y2: 723.90625,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 310.609375,
            y1: 721.109375,
            x2: 329.8125,
            y2: 746.109375,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 200.796875,
            y1: 730.71875,
            x2: 309.242919921875,
            y2: 761.71875,
            width: 1580,
            height: 2044,
            pageNumber: 1
          },
          {
            x1: 330.515625,
            y1: 730.71875,
            x2: 433.9454956054688,
            y2: 761.71875,
            width: 1580,
            height: 2044,
            pageNumber: 1
          }
        ],
        cfi: "epubcfi(/4/4!,/96/1:10,/106/1:8)"
      }
    ],
    color: "#fccbfc",
    question_context: "standard"
  },
  {
    user_type: "TEACHER",
    content: "They rarely smiled, these girls, they were so terribly serious.",
    task_id: null,
    user_uid: "vrkgZNsa1nOAbhZ3aQc1nZiIV1t1",
    interaction_id: "0e585dd3-a9dd-49aa-aa01-179bfb07004b",
    interaction_type: "ANSWER",
    interaction_subtype: "QUOTE",
    text_id: 4,
    course_id: 6,
    submission_id: null,
    privacy: "ME_ONLY",
    id: "4b560f4d-89ae-40ce-a291-1e28e4c9b640",
    cfi: "epubcfi(/4/4!/140,/1:0,/1:63)",
    isPdf: true,
    pdfPosition: [
      {
        pageNumber: 1,
        pageRects: [
          {
            x1: 546.34375,
            y1: 996.234375,
            x2: 1288.432250976562,
            y2: 1027.234375,
            width: 1580,
            height: 2044,
            pageNumber: 1
          }
        ],
        cfi: "epubcfi(/4/4!/140,/1:0,/1:63)"
      }
    ],
    color: "#fccbfc",
    question_context: "standard"
  },
  {
    user_type: "TEACHER",
    content:
      "How belated, our rage, when we should have raged at the beginning",
    task_id: null,
    user_uid: "vrkgZNsa1nOAbhZ3aQc1nZiIV1t1",
    interaction_id: "0e585dd3-a9dd-49aa-aa01-179bfb07004b",
    interaction_type: "ANSWER",
    interaction_subtype: "QUOTE",
    text_id: 4,
    course_id: 6,
    submission_id: null,
    privacy: "ME_ONLY",
    id: "197640d7-5580-4fd2-9037-081639faee49",
    cfi: "epubcfi(/4/6!,/22/1:53,/26/1:22)",
    isPdf: true,
    pdfPosition: [
      {
        pageNumber: 2,
        pageRects: [
          {
            x1: 818.7291259765625,
            y1: 542.6875,
            x2: 1380.6181640625,
            y2: 573.6875,
            width: 1580,
            height: 2044,
            pageNumber: 2
          },
          {
            x1: 200.796875,
            y1: 580.703125,
            x2: 480.2496337890625,
            y2: 611.703125,
            width: 1580,
            height: 2044,
            pageNumber: 2
          }
        ],
        cfi: "epubcfi(/4/6!,/22/1:53,/26/1:22)"
      }
    ],
    color: "#fccbfc",
    question_context: "standard"
  }
];

export const TEMP_KEY_CONCEPTS = [
  "Financial security",
  "Competiveness",
  "Job security",
  "Civic discourse",
  "Enjoyment of life",
  "Social mobility"
];

export const TASK_TYPES = [
  {
    key: TASK.TYPE.GUIDED_READING,
    value: "task.type.guidedReading"
  },
  {
    key: TASK.TYPE.STANDARD,
    value: "task.type.standard"
  }
];

export const TASK_TYPES_NEW = [
  {
    key: TASK.TYPE.JOURNEY,
    value: "task.type.journey"
  },
  {
    key: TASK.TYPE.STANDARD,
    value: "task.type.standardNew"
  },
  {
    key: TASK.TYPE.GUIDED_READING,
    value: "task.type.guidedReadingNew"
  }
];
