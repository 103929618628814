export const paletteLight = {
  primary: {
    main: "#1565C0",
    dark: "#003C8F",
    light: "#168FEE",
    contrast: "#FFFFFF"
  },

  // secondary: { main: "#5ec891" },
  secondary: {
    main: "#2E7D32",
    light: "#5E92F3",
    dark: "#1B5E20",
    contrast: "#FFFFFF",
    p12: "rgba(46, 125, 50, 0.12)"
  },
  warning: {
    main: "#ED6C02",
    dark: "#E65100",
    contrast: "#FFFFFF"
  },
  error: {
    main: "#D32F2F",
    dark: "#A32650",
    contrast: "#FFFFFF"
  },
  background: {
    default: "#FAFAFA",
    dark: "#121212",
    hover: "#c9c9c9",
    paper: "#FFFFFF",
    sidePanel: "#252525",
    rightSidePanel: "#FAFAFA",
    readerBar: "#1B5E20",
    blue: "#096BDE",
    studentView: "#e3edf7",
    submissionLayout: "#f5f5f5"
  },
  greenText: { main: "#226341", light: "#81C784" },
  card: {
    background: {
      main: "#ffffff",
      hover: "#f5f5f5",
      disabled: "#EEEEEE"
    }
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    blue: "#168FEE",
    green: "#2E7D32",
    secondary: "rgba(0, 0, 0, 0.6)",
    p4: "rgba(0, 0, 0, 0.04)",
    p12: "rgba(0, 0, 0, 0.12)",
    p30: "rgba(0, 0, 0, 0.30)",
    disabled: "#0000008A"
  },
  blue: {
    main: "#168fee",
    primary: "#003C8F",
    babyBlue: "#DDF1FF",
    babyLight: "#EDF2F4",
    secondary: "#1565C0",
    tertiary: "#90CAF9"
  },
  lightText: {
    main: "#FFFFFF",
    muted: "#0000008A"
  },
  grey: {
    main: "#c9c9c9",
    selected: "rgba(0, 0, 0, 0.08)",
    border: "#0000001F",
    paper: "#FFFFFF",
    surface: "#F5F5F5",
    toggleGrey: "#48485D"
  },
  newText: {
    secondary: "#00000099"
  },
  snackbar: {
    background: "rgba(49, 49, 49, 1)",
    text: "rgba(255, 255, 255, 0.7)",
    button: "rgba(255, 255, 255, 1)"
  },
  border: {
    main: "rgba(0, 0, 0, 0.3)",
    secondary: "rgba(0, 0, 0, 0.3)"
  },
  pieChart: {
    SubmissionPending_no: "#E0E0E0",
    SubmissionMissed_total: "#A32650",
    SubmissionMissed_incomplete: "#A32650",
    SubmissionMissed: "#A32650",
    SubmissionMissed_no: "#A32650",
    SubmissionPending_total: "#E0E0E0",
    SubmissionPending_complete: "#E0E0E0",
    SubmissionPending: "#E0E0E0",
    SubmissionLate_complete: "#96CD5F",
    SubmissionLate_incomplete: "#D3760D",
    SubmissionOnTime_complete: "#1B5E20",
    SubmissionOnTime_incomplete: "#4D9528",
    REVIEW_SESSION_total: "#1565C0",
    HIGHLIGHT_SESSION_total: "#5E92F3",
    ANSWER_SESSION_total: "#003C8F",
    empty: "#5E92F3",
    partial: "#1565C0",
    full: "#003C8F"
  },
  textEditor: {
    border: "rgba(0, 0, 0, 0.23)",
    placeholder: "rgba(0, 0, 0, 0.6)",
    bubbleMenu: {
      border: "rgba(61, 37, 20, 0.05)",
      inactiveBackgorund: "white",
      inactiveHover: "rgba(0, 0, 0, 0.1)",
      activeBackgorund: "rgba(0, 0, 0, 0.1)",
      activeHover: "rgba(0, 0, 0, 0.05)"
    }
  },

  engagementChip: {
    Low: { background: "#D32F2F", color: "#FFFFFF" },
    Moderate: { background: "#ED6C02", color: "#FFFFFF" },
    High: { background: "#2E7D32", color: "#FFFFFF" },
    VeryHigh: { background: "#1B5E20", color: "#FFFFFF" },
    teacher: { background: "#1565C0", color: "#FFFFFF" },
    risk: {
      background: "rgba(211, 47, 47, 0.12)",
      color: "rgba(0, 0, 0, 0.87)"
    }
  }
};
