import { httpCallables } from "../firebase";

import { addSnackbar } from "../redux/snackbarSlice";
import {
  setSubmissionDueDate,
  setTask,
  setTaskAndSubmissionsDueDate,
  removeTask,
  fetchTeacherCourseTasks
} from "../redux/tasksSlice";
import store from "../redux/store";
import { captureException } from "../utils/errorHandlers";
import { isEqual } from "date-fns";

const taskAPI = {
  undoDeleteTask: async function (task, interactions, submissions) {
    try {
      const { data } = await httpCallables.taskFunctions({
        func_name: "undoDeleteTask",
        task,
        interactions
      });

      if (data.success) {
        store.dispatch(setTask({ task, submissions }));
      } else {
        store.dispatch(
          addSnackbar({
            message: {
              id: "task.saving.error",
              defaultMessage: "There was a problem saving the draft"
            }
          })
        );
        let error = new Error(`UNDO_DELETE_TASK_DRAFT_FAILED`);
        error.message = data.error;
        error.data = { task };
        throw error;
      }
    } catch (err) {
      captureException(err);
    }
  },
  deleteTask: async function (task, noUndo = false) {
    const submissions = store
      .getState()
      .tasks.submissions.filter((s) => s.task_id === task.id);

    try {
      await httpCallables
        .taskFunctions({ func_name: "deleteTask", task_id: task.id })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            const { task, interactions } = JSON.parse(data.payload);
            store.dispatch(removeTask({ taskId: task.id }));
            noUndo
              ? store.dispatch(
                  addSnackbar({
                    message: {
                      id: "task.deleted",
                      defaultMessage: "Assignment deleted"
                    },
                    data: {
                      task,
                      interactions,
                      submissions
                    }
                  })
                )
              : store.dispatch(
                  addSnackbar({
                    message: {
                      id: "task.deleted",
                      defaultMessage: "Assignment deleted"
                    },
                    actions: [
                      {
                        intlId: "undo",
                        intlDefaultMsg: "undo",
                        callBack: "undoDeleteTask"
                      }
                    ],
                    data: {
                      task,
                      interactions,
                      submissions
                    }
                  })
                );
          } else {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "task.deleteFailed",
                  defaultMessage:
                    "There was a problem deleting the draft, please try again."
                }
              })
            );
            let error = new Error(`DELETE_TASK_DRAFT_FAILED`);
            error.message = data.error;
            error.data = { task };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    }
  },
  updateDueDateForStudent: async function (submission, due_date) {
    const submission_id = submission.id;
    due_date = new Date(due_date);
    try {
      await httpCallables
        .taskFunctions({
          func_name: "updateDueDateForStudent",
          submission_id,
          due_date: due_date
        })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            let prev_due_date =
              submission.due_date || submission.original_due_date;
            store.dispatch(
              setSubmissionDueDate({
                submission_id,
                due_date: due_date.toISOString()
              })
            );
            if (!isEqual(due_date, new Date(prev_due_date))) {
              store.dispatch(
                addSnackbar({
                  message: {
                    id: "task.dueDateChanged",
                    defaultMessage: "Due date changed successfully"
                  },
                  actions: [
                    {
                      intlId: "undo",
                      intlDefaultMsg: "undo",
                      callBack: "undoDueDateChangeForStudent"
                    }
                  ],
                  data: {
                    submission,
                    prev_due_date
                  }
                })
              );
            } else {
              store.dispatch(
                addSnackbar({
                  message: {
                    id: "task.dueDateChanged",
                    defaultMessage: "Restored changes"
                  }
                })
              );
            }
          } else {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "task.dueDateChangedFailed",
                  defaultMessage:
                    "There was a problem changing the due date, please try again."
                }
              })
            );
            let error = new Error(`UPDATE_DUE_DATE_FOR_STUDENT_FAILED`);
            error.message = data.error;
            error.data = { submission_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    }
  },
  updateDueDateForAllStudents: async function (task, due_date) {
    const task_id = task.id;
    due_date = new Date(due_date);
    try {
      const { course_id } = task;
      await httpCallables
        .taskFunctions({
          func_name: "updateDueDateForAllStudents",
          task_id,
          due_date,
          task,
          course_id
        })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            store.dispatch(
              setTaskAndSubmissionsDueDate({
                task_id,
                due_date: due_date.toISOString()
              })
            );
            let prev_due_date = task.original_due_date;
            if (due_date !== prev_due_date) {
              store.dispatch(
                addSnackbar({
                  message: {
                    id: "task.dueDateChanged",
                    defaultMessage: "Due date changed successfully"
                  },
                  actions: [
                    {
                      intlId: "undo",
                      intlDefaultMsg: "undo",
                      callBack: "undoDueDateChangeForAllStudents"
                    }
                  ],
                  data: {
                    task,
                    prev_due_date
                  }
                })
              );
            } else {
              store.dispatch(
                addSnackbar({
                  message: {
                    id: "task.dueDateChanged",
                    defaultMessage: "Restored changes"
                  }
                })
              );
            }
          } else {
            let error = new Error(`UNDO_CREATE_TASK_FAILED`);
            error.message = data.error;
            error.data = { task_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    }
  },
  undoPublishTask: async function (task_id, offline_task_id) {
    try {
      await httpCallables
        .taskFunctions({
          func_name: "undoPublishTask",
          task_id,
          offline_task_id
        })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            const { task } = JSON.parse(data.payload);
            store.dispatch(
              fetchTeacherCourseTasks({ course_id: task.course_id })
            );
          } else {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "undo.undoPublishTaskFailed",
                  defaultMessage: "Undo failed"
                }
              })
            );
            let error = new Error(`UNDO_CREATE_TASK_FAILED`);
            error.message = data.error;
            error.data = { task_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    }
  }
};

export default taskAPI;
