import { createTheme } from "@mui/material/styles";
import { BREAKPOINTS } from "../consts";

import { PaletteDark } from "./paletteDark";
import { paletteLight } from "./paletteLight";
import { Typography } from "./Typography";

const BaseStyles = {
  MuiCardContent: {
    styleOverrides: {
      root: {
        fontFamily: "Chivo",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 400
      }
    }
  },
  MuiButton: {
    root: {
      textTransform: "none",
      fontFamily: "Chivo",
      fontSize: 14,
      lineHeight: "24px",
      fontWight: 400
    }
  },
  MuiStepper: {
    root: {
      backgroundColor: "transparent"
    }
  },
  MuiInputBase: {
    input: {
      fontFamily: "Chivo",
      "&[type=number]": {
        MozAppearance: "textfield"
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      }
    }
  },
  MuiInput: {
    input: {
      "&::placeholder": {
        color: "white"
      }
    }
  },
  MuiStepLABEL: {
    fontSize: "24px"
  }
};

const Breakpoints = {
  values: {
    desktop: BREAKPOINTS.DESKTOP,
    xs: 660,
    sm: 1056, // Your XS size
    md: 1280, // Your M size
    lg: 1440, // Your L size
    xl: 1920
  }
};

const globalTheme = {
  components: {
    ...BaseStyles,
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: paletteLight.primary.dark,
          color: paletteLight.primary.contrast
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {}
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiDataGridMenu: {
      root: {
        "& .MuiPaper-root": {}
      }
    },
    "MuiPickersBasePicker-container": {},
    "MuiDataGridMenu-root": {},
    MuiPickersDay: {
      day: {
        color: "black"
      },
      daySelected: {
        backgroundColor: "#33abb6"
      },
      dayDisabled: {
        color: "#ccc"
      },
      current: {
        color: "red"
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#33abb6"
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked": {
            color: "#90CAF9"
          }
        },
        track: {
          backgroundColor: "#cfcfcf"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "black"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#333"
        }
      }
    }
  },
  palette: { ...paletteLight },
  typography: {
    ...Typography
  },
  breakpoints: Breakpoints
};

export function getGlobalTheme() {
  return createTheme(globalTheme);
}

const hebrewTheme = {
  isRtl: true,
  direction: "rtl",
  components: {
    ...BaseStyles,
    MuiPopover: {
      styleOverrides: {
        root: {
          direction: "rtl"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "black"
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: "#333"
      }
    }
  },
  palette: { ...paletteLight },
  typography: {
    ...Typography,
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
  },
  breakpoints: Breakpoints
};

export function getHebrewTheme() {
  return createTheme(hebrewTheme);
}

const globalDarkTheme = {
  components: {
    ...BaseStyles,
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: PaletteDark.primary.light,
          color: PaletteDark.primary.contrast
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedPrimary: {
          color: "#90CAF9",
          borderColor: "#90CAF9"
        },
        containedPrimary: {
          color: "black",
          backgroundColor: "#90CAF9",
          "&:hover": {
            backgroundColor: "#4eaaf5"
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    ...PaletteDark
  },
  typography: {
    ...Typography
  },
  breakpoints: Breakpoints
};

export function getGlobalDarkTheme() {
  return createTheme(globalDarkTheme);
}

const hebrewDarkTheme = {
  isRtl: true,
  direction: "rtl",
  components: {
    ...BaseStyles,
    MuiPopover: {
      root: {
        direction: "rtl"
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "white"
        },
        outlinedPrimary: {
          color: "white"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    ...PaletteDark
  },
  typography: {
    ...Typography,
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
  },
  breakpoints: Breakpoints
};
export function getHebrewDarkTheme() {
  return createTheme(hebrewDarkTheme);
}

// DatePicker Theme

export const datePickerLightTheme = createTheme({});
