import { configureStore, combineReducers } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import firebaseReducer from "./firebaseSlice";
import firestoreReducer from "./firestoreSlice";
import { createEpicMiddleware } from "redux-observable";
import { fetchAsObservable } from "./epics/utils";
import { rootEpic } from "./epics/rootEpic";

// Middlewares
import sq3rMiddleware from "./sq3rMiddleware";

// Other reducers
import routerReducer from "./routerSlice";
import readerActionsReducer from "./readerActionsSlice";
import highlightReducer from "./highlightSlice";
import grReducer from "./grSlice";
import linkSlice from "./LinkSlice";
import pdfSlice from "./pdfSlice";
import taskSlice from "./taskSlice";
import textReducer from "./textsSlice";
import userReducer from "./userSlice";
import coursesSlice from "./coursesSlice";
import tasksSlice from "./tasksSlice";
import interactionsSlice from "./interactionsSlice";
import realtimeInteractionsSlice from "./realtimeInteractionsSlice";
import snackbarSlice from "./snackbarSlice";
import layoutSlice from "./layoutSlice";
import readerSlice from "./readerSlice";
import chatSlice from "./chatSlice";
import fabSlice from "./fabSlice";
import emailModalSlice from "./emailModalSlice.js";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      user: state.user?.auth?.uid || "unknown"
    };
    return transformedState;
  }
});

const epicMiddleware = createEpicMiddleware({
  dependencies: { fetchAsObservable }
});

const combinedReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: routerReducer,
  layout: layoutSlice,
  readerActions: readerActionsReducer,
  highlighter: highlightReducer,
  gr: grReducer,
  texts: textReducer,
  task: taskSlice,
  user: userReducer,
  link: linkSlice,
  courses: coursesSlice,
  tasks: tasksSlice,
  interactions: interactionsSlice,
  realtimeInteractions: realtimeInteractionsSlice,
  snackbars: snackbarSlice,
  pdf: pdfSlice,
  reader: readerSlice,
  chat: chatSlice,
  fab: fabSlice,
  emailModal: emailModalSlice
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["firebase/setAuth"],
        ignoredPaths: ["firebase", "firestore"]
      }
    }).prepend(epicMiddleware, sq3rMiddleware)
});

epicMiddleware.run(rootEpic);

export default store;
