import { createSlice, current, createSelector } from "@reduxjs/toolkit";
import { determinePDFPagePosition } from "../components/reader/pdf/utils";
import { FEATURES, TEXT_TYPE, USER_PROFILE } from "../consts";
import { captureException } from "../utils/errorHandlers";
import { selectPageVisibility } from "./pdfSlice";
export const defaultProfile = {
  darkMode: false,
  fontSize: "6",
  location: "/",
  showCitationsTip: true,
  selectedCourseId: 0
};
import { updateDocument } from "./firestoreSlice";

//Thunk
export function toggleDarkMode(darkMode) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { darkMode };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(err, `toggleDarkMode failed. user: ${userId}`);
    }
  };
}

export function acceptTermsOfUse() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { acceptedTermsOfUse: true };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(err, `acceptTermsOfUse failed. user: ${userId}`);
    }
  };
}

export function updateAlertsDuration(duration) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;

    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { alertsDuration: duration };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(err, `updateAlertsDuration failed. user: ${userId}`);
    }
  };
}

export function updateTextLocation(params) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const { text_id, position, type, lastPage } = params;
    let newPosition = position;
    if (type === TEXT_TYPE.PDF) {
      const { currentPage } = state.pdf;
      const currentPageVisibility = selectPageVisibility(state, currentPage);
      const previusPageVisibility = selectPageVisibility(
        state,
        currentPage - 1
      );
      const nextPageVisibility = selectPageVisibility(state, currentPage + 1);

      newPosition = determinePDFPagePosition(
        currentPageVisibility,
        previusPageVisibility,
        nextPageVisibility
      );
    }
    try {
      const path = `textLocations/${userId}/texts/${text_id}`;
      const payload = {
        lastPage: lastPage,
        position: newPosition,
        type: type
      };
      dispatch(updateDocument(path, payload));
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update user's text location. user ${userId}`
      );
      return false;
    }
  };
}

export function updateAvatar(url) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { photoURL: url };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(err, `updateAvatar failed. user: ${userId}`);
    }
  };
}

export function changeLocale(locale) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { locale: locale.tag };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(
        err,
        `changeLocale failed. user: ${userId}, locale: ${locale}`
      );
    }
  };
}

export function changeSidebarWidth(width) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`;
      const payload = { sidebarWidth: width };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(
        err,
        `changeSidebarWidth failed. user: ${userId}, sidebarWidth: ${width}`
      );
    }
  };
}

export const setProfile = (profileData) => async (dispatch, getState) => {
  try {
    // First dispatch the action to update Redux state
    dispatch(setProfileState(profileData));

    // Then update Firebase if the user is authenticated
    const state = getState();
    const uid = state.firebase.auth.uid;
    if (uid) {
      dispatch(updateDocument(`users/${state.firebase.auth.uid}`, profileData));
    }
  } catch (err) {
    captureException(err, `setUserProfile failed`);
  }
};

export const setPersistent = (data) => async (dispatch, getState) => {
  try {
    // First dispatch the action to update Redux state
    dispatch(setPersistentState(data));

    // Then update Firebase if the user is authenticated
    const state = getState();
    const uid = state.firebase.auth.uid;
    if (uid) {
      const keys = Object.keys(data);
      await Promise.all(
        keys.map(async (key) => {
          dispatch(updateDocument(`users/${uid}/persistent/${key}`, data[key]));
        })
      );
    }
  } catch (err) {
    captureException(err, `setPersistentData failed`);
  }
};

const initialState = {
  userProfile: defaultProfile,
  userProfileSet: false,
  snackbarContent: {},
  openTasks: [],
  openTextTasks: [],
  fontSizeOptions: {
    5: {
      h1: {
        "font-size": "32px !important",
        "line-height": "40px !important"
      },
      h4: {
        "font-size": "18px !important",
        "line-height": "26px !important"
      },
      h2: {
        "font-size": "22px !important",
        "line-height": "30px !important",
        "margin-top": "36px",
        "margin-bottom": "20px"
      },
      h3: {
        "font-size": "22px !important",
        "line-height": "22px !important"
      },
      "h1.h.sigil_not_in_toc": {
        "font-size": "18px !important",
        "line-height": "30px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "12px !important",
          "line-height": "30px !important"
        },
      body: {
        "font-size": "20px !important",
        "line-height": "30px !important"
      },
      ".info": {
        "font-size": "14px !important",
        "line-height": "22px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "22px !important",
        "line-height": "22px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "12px !important",
        "line-height": "20px !important"
      }
    },
    6: {
      h1: {
        "font-size": "34px !important",
        "line-height": "42px !important"
      },
      h4: {
        "font-size": "20px !important",
        "line-height": "28px !important"
      },
      h2: {
        "font-size": "24px !important",
        "line-height": "32px !important",
        "margin-top": "40px",
        "margin-bottom": "24px"
      },
      h3: {
        "font-size": "24px !important",
        "line-height": "24px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "14px !important",
          "line-height": "32px !important"
        },
      body: {
        "font-size": "22px !important",
        "line-height": "32px !important"
      },
      ".info": {
        "font-size": "16px !important",
        "line-height": "24px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "24px !important",
        "line-height": "24px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "14px !important",
        "line-height": "22px !important"
      }
    },
    7: {
      h1: {
        "font-size": "36px !important",
        "line-height": "44px !important"
      },
      h4: {
        "font-size": "22px !important",
        "line-height": "30px !important"
      },
      h2: {
        "font-size": "26px !important",
        "line-height": "34px !important",
        "margin-top": "48px",
        "margin-bottom": "32px"
      },
      h3: {
        "font-size": "26px !important",
        "line-height": "26px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "16px !important",
          "line-height": "34px !important"
        },
      body: {
        "font-size": "24px !important",
        "line-height": "34px !important"
      },
      ".info": {
        "font-size": "18px !important",
        "line-height": "26px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "26px !important",
        "line-height": "26px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "16px !important",
        "line-height": "24px !important"
      }
    },
    8: {
      h1: {
        "font-size": "38px !important",
        "line-height": "46px !important"
      },
      h4: {
        "font-size": "24px !important",
        "line-height": "32px !important"
      },
      h2: {
        "font-size": "28px !important",
        "line-height": "36px !important",
        "margin-top": "40px",
        "margin-bottom": "40px"
      },
      h3: {
        "font-size": "28px !important",
        "line-height": "28px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "18px !important",
          "line-height": "36px !important"
        },
      body: {
        "font-size": "26px !important",
        "line-height": "36px !important"
      },
      ".info": {
        "font-size": "24px !important",
        "line-height": "28px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "228x !important",
        "line-height": "28px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "18x !important",
        "line-height": "26px !important"
      }
    }
  },
  //...loadState(),
  offline: false,
  loggedOut: false,
  auth: -1,
  original_auth: -1,
  tasksViewed: [],
  selectedCourseId: 0,
  persistent: {}
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => {
      return { ...initialState, loggedOut: true };
    },
    setOriginalUser: (state, value) => {
      let auth = current(state.auth);
      if (state.original_auth === -1 || value.payload === -1) {
        state.original_auth = value.payload;

        //don't allow override
      }

      if (value.payload !== -1) {
        state.auth = {
          displayName: auth.uid,
          photoURL: "",
          uid: auth.uid,
          email: auth.uid
        };
      }
    },

    setAuth: (state, value) => {
      state.auth = value.payload;
    },
    setPersistentState: (state, value) => {
      const keys = Object.keys(value.payload);
      keys.forEach((key) => {
        state.persistent[key] = value.payload[key];
      });
    },
    setProfileState: (state, value) => {
      state.userProfile = value.payload;
      state.userProfileSet = true;
    },
    toggleOpenTask: (state, value) => {
      let currentTasks = current(state.openTasks);

      if (currentTasks.includes(value.payload)) {
        state.openTasks = currentTasks.filter((e) => e !== value.payload);
      } else state.openTasks = [...currentTasks, value.payload];
    },
    setOffline: (state, value) => {
      state.offline = value.payload;
    },
    toggleOpenTextTask: (state, value) => {
      let currentTasks = current(state.openTextTasks);
      if (currentTasks.includes(value.payload)) {
        state.openTextTasks = currentTasks.filter((e) => e !== value.payload);
      } else state.openTextTasks = [...currentTasks, value.payload];
    },
    setTasksViewed: (state, value) => {
      state.tasksViewed = value.payload || [];
    }
  }
});

export const {
  setProfileState,
  setAuth,
  setOriginalUser,
  toggleOpenTask,
  toggleOpenTextTask,
  setOffline,
  logout,
  setPersistentState,
  setTasksViewed
} = userSlice.actions;

export default userSlice.reducer;

// Selectors
export const selectIsImpersonation = (state) => {
  if (state.user?.original_auth) {
    return state.user?.original_auth !== -1;
  } else return false;
};

export const taskViewed = createSelector(
  [
    (state) => state.user.tasksViewed,
    (state) => state.tasks.activeGrTaskId,
    (state) => state.tasks.selectedTaskId,
    (state) => state.router.location.pathname
  ],
  (tasksViewed, activeGrTaskId, selectedTaskId, pathname) => {
    let taskId;
    if (pathname.includes("task")) {
      // this is a standard task
      taskId = selectedTaskId;
    } else {
      // this is a gr task
      taskId = activeGrTaskId;
    }
    return tasksViewed.find((id) => id === taskId) !== undefined;
  }
);
