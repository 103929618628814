import { firestore } from "../firebase";
import { query, collection, where, getDocs } from "firebase/firestore";

export const checkFeatureFlag = async (flag, courseId, institutionId) => {
  try {
    if (!flag) {
      return false;
    }
    // Create and execute query
    const flagsQuery = query(
      collection(firestore, "featureFlags"),
      where("flag", "==", flag)
    );

    // Get documents
    const querySnapshot = await getDocs(flagsQuery);
    const featureFlags = querySnapshot.docs.map((doc) => doc.data());

    if (!featureFlags.length) return false;

    // Check course level
    const courseFlag = featureFlags.find(
      (doc) => doc.level === "course" && doc.targetId === courseId
    );
    if (courseFlag) return courseFlag.enabled;

    // Check institution level
    const institutionFlag = featureFlags.find(
      (doc) => doc.level === "institution" && doc.targetId === institutionId
    );
    if (institutionFlag) return institutionFlag.enabled;

    // Check default level
    const defaultFlag = featureFlags.find((doc) => doc.level === "default");
    if (defaultFlag) return defaultFlag.enabled;

    return false;
  } catch (error) {
    console.error("Error checking feature flag:", error);
    return false;
  }
};
